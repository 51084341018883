/*
 * @Author: 张国欣
 * @Date: 2024-06-20 14:27:17
 * @LastEditors: 张国欣
 * @LastEditTime: 2024-06-23 11:13:30
 * @FilePath: /survey_frontend/src/api/index.js
 * @Description: 
 */
import fetch from "./fetch"
let BASE_URL = 'http://survey.jsds.tech/survey' // 示例 服务端请求域名
const protocol = window.location.protocol
const host = window.location.host
if (!host.includes('localhost')) {
  BASE_URL = `${protocol}//${host}/survey`
}
// 微信登录
export const loginApi = (params) => fetch(BASE_URL + '/user_api/wechat_auth_login', params, 'post')
// 手机登录
export const mobileLogin = (params) => fetch(BASE_URL + '/user_api/mobile_login', params, 'post')
// 3.1.14	手机号码注册
export const mobileRegister = (params) => fetch(BASE_URL + '/user_api/mobile_register', params, 'post')
// 3.1.15	重置登录密码
export const resetPwd = (params) => fetch(BASE_URL + '/user_api/reset_password', params, 'post')

// 设置或更新绑定的手机号
export const bindMobile = (params) => fetch(BASE_URL + '/user_api/set_bind_mobile', params, 'post')
// 用户答题记录
export const answerRecord = (params) => fetch(BASE_URL + '/user_api/get_user_answer_record', params, 'post')
// 查询用户积分记录，按时间（id）倒序。
export const scoreRecord = (params) => fetch(BASE_URL + '/user_api/get_user_score_record', params, 'post')
// 查询用户提现兑换记录，按时间（id）倒序。
export const redeemRecord = (params) => fetch(BASE_URL + '/user_api/get_user_redeem_record', params, 'post')
// 查询用户信息
export const getUserInfo = (params) => fetch(BASE_URL + '/user_api/get_user_info', params, 'post')
// 设置用户非敏感信息，空值不覆盖有值
export const setNonsensitiveInfo = (params) => fetch(BASE_URL + '/user_api/set_nonsensitive_info', params, 'post')
// 设置用户绑定的手机号码信息
export const setMobile = (params) => fetch(BASE_URL + '/user_api/set_mobile', params, 'post')
// 设置用户支付宝账号
export const setAlipayAcc = (params) => fetch(BASE_URL + '/user_api/set_alipay_acc', params, 'post')
// 设置用户登录密码
export const setPassword = (params) => fetch(BASE_URL + '/user_api/set_password', params, 'post')
// 查询用户扩展属性
export const getExtProperty = (params) => fetch(BASE_URL + '/user_api/get_ext_property', params, 'post')
// 设置用户扩展属性，空值不覆盖有值
export const setExtProperty = (params) => fetch(BASE_URL + '/user_api/set_ext_property', params, 'post')
// 3.1.16	MGM邀请信息查询
export const getInvitationInfo = (params) => fetch(BASE_URL + '/user_api/get_mgm_invitation_info', params, 'post')
// 问卷列表
export const getQList = (params) => fetch(BASE_URL + '/questionnaire_api/get_questionnaire_list', params, 'post')
// 查询问卷详细信息
export const getQInfo = (params) => fetch(BASE_URL + '/questionnaire_api/get_questionnaire_info', params, 'post')
// 用户发起答题申请，进行相关校验，获得答题链接。
export const launchAnswer = (params) => fetch(BASE_URL + '/questionnaire_api/launch_answer', params, 'post')
// 查询指定问卷成功作答的记录，
export const getFinishedAList = (params) => fetch(BASE_URL + '/questionnaire_api/finished_answer_list', params, 'post')
// 查询待上传答题凭证的列表
export const getNeedPicList = (params) => fetch(BASE_URL + '/questionnaire_api/get_image_upload_pending_answers', params, 'post')
// 对指定的答题记录上传图片
export const uploadAnswerPictures = (params) => fetch(BASE_URL + '/questionnaire_api/upload_answer_pictures', params, 'post')
// 发起积分提现操作
export const scoreRedeem = (params) => fetch(BASE_URL + '/payment_api/score_redeem', params, 'post')
// 获取短信验证码
export const getSmsCaptcha = (params) => fetch(BASE_URL + '/misc_api/get_sms_captcha', params, 'post')
// 当前绑定手机号获取短信验证码
export const getBindSmsCaptcha = (params) => fetch(BASE_URL + '/misc_api/get_bind_mobile_sms_captcha', params, 'post')
// 验证当前绑定手机号的验证码，用于修改手机预检
export const validateChangeCaptcha = (params) => fetch(BASE_URL + '/misc_api/validate_change_mobile_precheck_captcha', params, 'post')
// 获取OSS的临时凭证信息
export const getPictureStoreCredential = (params) => fetch(BASE_URL + '/misc_api/get_picture_store_credential', params, 'post')
// 3.7.1	外部推广问卷详情
export const getExtQInfo = (params) => fetch(BASE_URL + '/ext_promote_api/get_questionnaire_info', params, 'post')
// 3.7.2	外部推广发起答题
export const launchExtAnswer = (params) => fetch(BASE_URL + '/ext_promote_api/launch_answer', params, 'post')
