/*
 * @Author: 张国欣
 * @Date: 2024-06-20 14:27:17
 * @LastEditors: 张国欣
 * @LastEditTime: 2024-06-23 11:45:03
 * @FilePath: /survey_frontend/src/api/fetch.js
 * @Description: 
 */
import axios from 'axios'
import { isInWeiXin } from '../utils/index'
export default function fetch (url= '', data = {}, method = 'get') {
  // const ua = navigator.userAgent.toLocaleLowerCase()
  method = method.toLowerCase()
  const commonData = {
    version: "1.0",
    token: localStorage.getItem('SURVEY_token'),
  }
  let ops = {data: Object.assign(commonData, data)}
  let options = {
    url,
    ...ops,
    method
  }

  // 返回一个promise对象
  return new Promise((resolve, reject) => {
    const instance = axios.create({ // 创建一个axios实例
      timeout: 10000, // 设置请求超时设置
      withCredentials: true
    })

    instance(options) // 请求处理
      .then(({ data }) => {
        if (data.code === 'SYS_1201' || data.code === 'SYS_1202') { // 登录信息失效
          localStorage.removeItem('SURVEY_token')
          const appid = 'wxc6148c94b48d508a'
          let url = encodeURIComponent(location.href)
          if (isInWeiXin()) {
            url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
            location.replace(url)
            // console.log('url:', url)
          } else {
            const protocol = window.location.protocol
            const host = window.location.host
            url = `${protocol}//${host}/login?returnUrl=${url}`
            location.replace(url)
          }
          return false
        } else {
          resolve(data) // 不论返回的success是true还是false，都进resolve，后续逻辑前端处理
        }
        
      })
      .catch((error) => {
        console.log('fetch请求数据失败！', error)
        // resolve(data) // 不论返回的success是true还是false，都进resolve，后续逻辑前端处理
        reject(data)
      })
  })
}
